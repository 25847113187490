import React, { Component } from 'react';
import './Works.scss';
import Video from "../Video/Video";

class Works extends Component {
  render() {
    return (
      <section className="worklist">
        <header>
          <h2>How it works</h2>
        </header>
        <div className="col-12 centercontant">
          {/* <div className='bgposition'>
            <p>Fostering healthcare globally and leaving<em>no one behind</em> </p>
          </div> */}
          <Video />
        </div>
      </section>
    );
  }
}

export default Works;