import React, { Component } from "react";
import "./Video.scss";
import ReactPlayer from "react-player";
import Cortex_WebSite from "../../video/Cortex_WebSite_v2.mp4";

class Video extends Component {
  render() {
    return (
      <section className="video">
        <div
          className="controlsection"
          onContextMenu={(e) => e.preventDefault()}
        >
          <ReactPlayer
            onContextMenu="return flase;"
            className="video-size"
            playing
            muted
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  autoPlay: true,
                  muted: true,
                },
              },
            }}
            url={Cortex_WebSite}
            loop={true}
            controls={true}
          />
        </div>
      </section>
    );
  }
}

export default Video;
