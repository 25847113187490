import React, { Component } from 'react';
import './Turst.scss';
import logo1 from "../../images/logo/logo1.png";
import logo2 from "../../images/logo/logo2.png";
import logo3 from "../../images/logo/logo3.PNG";
import logo4 from "../../images/logo/logo4.png";



class Turst extends Component {
  render() {
    return (
      <section className="turstlist">
        <header>
          <h2>Our Customers</h2>
        </header>
        <div className=" turstcontant">
            <div className=' pt-3 pb-3 col-12 logoimage'>
              <div className='logo-box col-12 col-sm-6 col-md-6 col-xl-3 col-lg-3'>
                <a href='https://royalhealthheart.ae/' target="_blank" rel="noreferrer"><img type="img" src={logo1} alt='logoimg'/></a>
              </div>
              <div className='logo-box col-12 col-sm-6 col-md-6 col-xl-3 col-lg-3'>
                <a href='https://iheal.global/' target="_blank" rel="noreferrer"><img type="img" src={logo4} alt='logoimg'/></a>
              </div>
              <div className='logo-box col-12 col-sm-6 col-md-6 col-xl-3 col-lg-3'>
                <a href='https://www.emcahomecare.com/about/' target="_blank" rel="noreferrer"><img type="img" src={logo3} alt='logoimg'/></a>
              </div>
              <div className='logo-box col-12 col-sm-6 col-md-6 col-xl-3 col-lg-3'>
                <a href='https://royalhealthrehab.ae/' target="_blank" rel="noreferrer"><img type="img" src={logo2} alt='logoimg'/></a>
              </div>
            </div>
        </div>
        {/* <div className='col-12 boximage-parent'>
          <div className='pt-3 pb-3 boximage'>
            <span className='box'>
            </span>
          </div>
          <div className=' pt-3 pb-3 boximage'>
            <span className='box'>
            </span>
          </div>
          <div className=' pt-3 pb-3 boximage'>
            <span className='box'>
            </span>
          </div>
          <div className=' pt-3 pb-3 boximage'>
            <span className='box'>
            </span>
          </div>
          <div className='pt-3 pb-3 boximage'>
            <span className='box'>
            </span>
          </div>
        </div> */}

      </section>
    );
  }
}

export default Turst;