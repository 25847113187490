import React, { Component } from 'react';
import './HomeList.scss';
import Frame1 from '../../images/icons/Frame-1.svg';
import Frame2 from '../../images/icons/Frame-2.svg';
import Frame3 from '../../images/icons/Frame-3.svg';
import Frame4 from '../../images/icons/Frame-4.svg';
import Frame5 from '../../images/icons/Frame-5.svg';
import Frame6 from '../../images/icons/Frame-6.svg';

const listData = [
  {
    background: Frame1,
    head: 'Scalability',
    description: 'World-class database design for handling millions of patient records.'
  },
  {
    background: Frame2,
    head: 'Collaboration',
    description: 'Digitized clinical workflow enabling Healthcare professionals collaborate more efficiently.'
  },
  {
    background: Frame3,
    head: 'Preset Protocols',
    description: 'Manage your population health through a patient specific preset lifestyle and medical protocols.'
  },
  {
    background: Frame4,
    head: 'Quick Service Turnup',
    description: 'Launch your virtual healthcare and population health management services in less than a week with easy to manage subscription.'
  },
  {
    background: Frame5,
    head: 'Appointments & Payments',
    description: 'Integrated appointment and payment modules via patient’s smart devices.'
  },
  {
    background: Frame6,
    head: 'Cost Effective',
    description: 'HIVE - our home tablet will help you simplify the whole family engagement with health providers.'
  },

];

class HomeList extends Component {

  render() {
    return (
      <section className="homelist">
        <header>
          <h2>Why us?</h2>
        </header>
        {/* <div className="row"> */}
        <div className="row listcontainer d-flex">
        { listData && listData.map(val =>(
          <div className="col-l2 col-sm-6 col-md-6 col-lg-4 col-xl-4 d-flex pb-md-5 pt-md-5 pb-3 pt-3">
            <span className="imgbg col-3 ">
              <img type="img" src={val.background} alt='background'/>
            </span>
            <span className='col-9'>
              <label>{val.head}</label>
              <p>{val.description}</p>
            </span>
          </div>
            ))}

        </div>
        {/* </div> */}
        
      </section>
    );
  }
}

export default HomeList;