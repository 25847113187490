import React from "react";
import "./home.scss";
import HomeList from "../HomeList";
import Works from "../Works";
import Clients from "../Clients";
import Turst from "../Turst";
// import Chat from "../Chat";
import Partners from "../Partners";


const Home = () => (
  <div className="main">
    <section className=" mainbg-color">
      <div className="col-12 sec-split">
        <div className="flex col-12 col-sm-12 col-md-12 col-xl-8 col-lg-8">
          <div className="left-flex">
          <h1 className="title is-1 sec-one">Digitized clinical workflow for more advanced virtual healthcare services</h1>
          <p className="sec-two">
          Get holistic patient information in one dashboard to remotely monitor, diagnose and treat patients more effectively, with our advanced AI based applications
          </p>
              <form className="contactPageForm">
                  <div className="col-12 formBoxSection d-flex p-0">
                    <input type="text" className="contactPageInput" placeholder="Get to know more" />
                    <a  href="/contact-us" className="btn contactpageBtn">ENQUIRE</a>
                  </div>
              </form>
          </div>
         
            
        </div>
        <div className="col-4 right-flex">
          <span className="sec-split-two">
          {/* <img src={HomeBanner} /> */}
          </span>
          
        </div>
      </div>

    </section>
    <HomeList/>
    <Turst/>
    <Partners/>
    <Works/>
    <Clients/>
    {/* <Chat/> */}

  </div>
);

export default Home;
