import React, { Component } from 'react';
import './Footer.scss';
import Headerlogo from "../../images/logo/Headerlogo.svg";
// import Instagram from "../../images/icons/instagram.svg";
import Facebook from "../../images/icons/facebook.svg";
import linkdin from "../../images/icons/linkdin.png";
import Youtube from "../../images/icons/youtube.svg";
import applelogo from "../../images/icons/applelogo.jpg";
import googleplay from "../../images/icons/googleplay.png";
import appgallery from "../../images/icons/appgallery.png";
import Hipaa from "../../images/icons/Hipaa.png"

class Footer extends Component {
  render() {
    return (
      <section className='footer'>
        <div className='col-12 footer-section'>
          <div className='logoSection col-12 col-sm-6 col-md-6 col-xl-2 col-lg-2 p-0 Firstchild'>
            <img type="img" src={Headerlogo} alt='logo' />
            <p>Fostering healthcare globally & leaving no one behind</p>
            <ul className="publicIcon">
              {/* <li><img type="img"  src={Instagram} alt='instagram'/></li> */}
              <li><a href="https://www.youtube.com/channel/UC1jrl4j8Dsy-3YZHX1VVdsw" target="_blank" rel="noreferrer"><img type="img" src={Youtube} alt="youtube" /></a></li>
              <li><a href="https://www.facebook.com/MyCortex-100503544993319/?modal=admin_todo_tour" target="_blank" rel="noreferrer"><img type="img" src={Facebook} alt='facebook' /></a></li>
              <li><a href="https://www.linkedin.com/company/mycortex/?viewAsMember=true" target="_blank" rel="noreferrer"><img type="img" src={linkdin} alt='facebook' /></a></li>
              {/* <li><img type="img" src={Twitter} alt="twitter"/></li> */}
            </ul>
          </div>
          <div className='logoSection'>
            <label>Products</label>
            <ul>
              <li><a href="/products#Cortex">Hive SaaS</a></li>
              <li><a href="/products#Hive">Hive</a></li>
              <li><a href="/products#Chart">HiveChart</a></li>
              <li><a href="/products#Pod">HivePod</a></li>
              <li><a href="/products#MyCE">HiveCE</a></li>
              {/* <li><a href="/products/#CogniFlow">CogniFlow</a></li> */}
              {/* <li><a href="/products/#Conferencing">Video Conferencing</a></li> */}
              {/* <li><a href="/flowmed/products/#HiveMeet">Hive Meet</a></li> */}
            </ul>
          </div>
          {/* <div className='logoSection'>
            <label>Resources</label>
            <ul>
              <li>UseCases</li>
              <li>Blog</li>
            </ul>
          </div> */}
          <div className='logoSection'>
            <label>Company</label>
            <ul>
              <li><a href='/aboutus'>About Us</a></li>
              <li><a href='/privacy-policy'>Privacy Policy</a></li>
              <li><a href='/solution'>Solution</a></li>
              <li><a href='/contact-us'>Contact Us</a></li>
              <li><em><a href="mailto:sales@flowmed.ca">sales@flowmed.ca</a></em></li>
            </ul>
          </div>
          <div className='logoSection'>
            <label>Know more</label>
            <form className="logoForm">
              <div className="col-12 p-0 pt-3 formBoxSection">
                <input type="text" className="emailInput" placeholder="Your email here..." />
              </div>
              <div className="col-12 p-0 formBoxSection">
                <button className="btn contactpageBtn">SUBSCRIBE</button>
              </div>
            </form>
          </div>

        </div>
        <div className='col-12'>
          <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 appstore'>
            <ul className="applogos">
              {/* <li><img type="img"  src={Instagram} alt='instagram'/></li> */}
              <li><a href="https://apps.apple.com/ae/app/mycortex/id1542570655" target="_blank" rel="noreferrer"><img type="img" src={applelogo} alt="applogo" /></a></li>
              <li><a href="https://play.google.com/store/apps/details?id=com.mycortex.demo" target="_blank" rel="noreferrer"><img type="img" src={googleplay} alt='googlelogo' /></a></li>
              <li><a href="https://appgallery.huawei.com/#/app/C103128919" target="_blank" rel="noreferrer"><img type="img" src={appgallery} alt='appgallery' /></a></li>
              <li><a href="https://compliancy-group.com/hipaa-compliance-verification/" target="_blank" rel="noreferrer"><img type="img" src={Hipaa} alt='hipaa' /></a></li>

              {/* <li><img type="img" src={Twitter} alt="twitter"/></li> */}
            </ul>
          </div>
        </div>
      </section>

    );
  }
}

export default Footer;