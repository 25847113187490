import React, { Component } from 'react';
import './Partners.scss';
import amazon from "../../images/logo/amazon.png";
import Eti from "../../images/logo/etisalat.png";
import Google from "../../images/logo/google.png";
import Apple from "../../images/logo/apple.png";
import Micro from "../../images/logo/micro.png";
import Omron from "../../images/logo/omron.png";
import Fitbit from "../../images/logo/fitbit.png";
import Choice from "../../images/logo/choice.png";
import Roche from "../../images/logo/roche.png";
import Fora from "../../images/logo/Fora.png";
import Oracle from "../../images/logo/Oracle-Cloud-Infrastructure.png";
// import Etisalat from "../../images/logo/etisalat.jpg";

class Partners extends Component {

  render() {
    return (
      <section className='partners'>
         <header>
          <h2>Technology Partners</h2>
        </header>
<div className='add-logo col-12'>
<div className='logo oracle'>
    <img type="img" src={Oracle} alt='amg'/>
  </div>
  <div className=' logo'>
    <img type="img" src={amazon} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Eti} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Google} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Apple} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Micro} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Omron} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Choice} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Roche} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Fitbit} alt='amg'/>
  </div>
  <div className='logo'>
    <img type="img" src={Fora} alt='amg'/>
  </div>
</div>
      </section>
    );
  }
}

export default Partners;