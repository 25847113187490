import React, { useState } from "react";
import "./about.scss";
import Hakim from "../../images/banner/Hakim.png";
import Regi from "../../images/banner/REGINALD.png";
import Placid from "../../images/banner/PLACID.png";
import Dhyaaldin from "../../images/banner/Dhyaaldin.png";
import rightarrow from "../../images/icons/rightarrow.png";
import stratoslogo from "../../images/banner/stratos_logo.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function ReadMore({ children, maxCharacterCount = 300 }) {
  const text = children;
  const [isTruncated, setIsTruncated] = useState(true);

  const resultString = isTruncated ? text.slice(0, 397) : text;

  function toggleIsTruncated() {
    setIsTruncated(!isTruncated);
  }

  return (
    <p className="">
      {resultString}
      {!isTruncated && (
        <>
          <br />
          <p>
          Hive by FlowMED is our answer to a cohesive system that creates an engagement workflow encompassing the wellness-to-treatment journey, delivering better outcomes over time.  Through HiveLite on smart devices, we empower users to be part of an inclusive collaboration scheme.
            <br />
            <br />
            Healthcare operators may subscribe to Hive SaaS offerings and outsource their Virtual Health Management requirements, which include full engagement with remote clinical/wellness program participants.
            <br />
            <br />
            The IoMT devices from different technology providers present healthcare operators with the challenge of dealing with medical data silos and cumbersome data integration.  FlowMED has developed an open integration system that consolidates the collection of patient’s personal medical data from smart devices, empowering them to conduct self care wherever they are.
          </p>
        </>
      )}
      <span onClick={toggleIsTruncated} className="">
        {isTruncated ? "... Read More" : "... Read Less"}
      </span>
    </p>
  );
}

const listData = [
  {
    background: Hakim,
    name: "Hakim Alhusan",
    position: "Co-Founder",
    description:
      "ICT & Telecom Veteran with over 30 years of engagement in executive capacity. An advisor for innovative technology start-ups in the AI, IOT and Telecom equipment providers.",
  },
  {
    background: Regi,
    name: "Reginald Samuel",
    position: "Co-Founder",
    description:
      "Rich expertise in Health Informatics over 20 years. Designed and implemented Digital Health transformation solutions for renowned organizations and regulators.",
  },
  {
    background: Placid,
    name: "Dr. Placid Paul",
    position: "Clinical Advisor",
    description:
      "Experienced in emergency medicine and surgery in Australia and in Canada. He has been an early adopter of technology solutions and one of the early waves of clinicians to adopt software enabled medical home visit logistics and virtual care.",
  },
  {
    background: Dhyaaldin,
    name: "Dr. Dhyaaldin Al Sarairah",
    position: "Clinical Advisor",
    description:
      "American/Jordanian board certified physician. Currently working as Migration Health Physician at IOM - UN Migration. Founder of iHEAL an NGO providing population health services for refugees in Jordan.",
  },
  {
    background: stratoslogo,
    name: "Stratos Innovation GRP",
    position: "Collaborator",
    description:
      "Stratos under Collaborator Stratos Innovation Group Human-centered design firm working to enhance the adoption of the solution by all stakeholders. Upfront consulting on Clinical Workflow to set up effective processes and context for maximizing the impact of the solution from rollout to tracking.",
  },
];

const RenderPopup = ({ lastcard, item, index }) => {
  return (
    <Popup
      trigger={<img src={rightarrow} alt="rightarrow" />}
      position={`absolute ${lastcard === index + 1 ? "right" : "center"}`}
    >
      {(close) => (
        <div className="Popupmodel">
          <div className="col-sm-12 col-md-6 col-lg-6 col-lg-6 img_contant">
            <img type="img" src={item.background} alt="background" />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-lg-6 leader_contant">
            <label>{item.name}</label>
            <p>{item.position}</p>
            <p>{item.description}</p>
          </div>
          <a className="close" onClick={close}>
            &times;
          </a>
        </div>
      )}
    </Popup>
  );
};

function aboutlist() {
  return (
    <div className="aboutlist">
      <section className=" aboutbg-color">
        <div className="col-12 sec-split">
          <div className="flex col-12 col-sm-12 col-md-12 col-xl-8 col-lg-8">
            <div className="left-flex">
              <h1 className="title is-1 sec-one">Our Story</h1>
              <p className="sec-two">
                <ReadMore>
                In today’s healthcare scene, primary care services address the treatment of active chronic conditions through inconvenient frequent visits to health institutions.  This is more akin to “sick care” rather than health care.  To efficiently scale health care delivery, it is prudent to change the paradigm to include preventive wellness programs, early detection, and then treatment—a flow that would expand the role to avoid or delay the progression of chronic conditions.<br></br>
                </ReadMore>
              </p>
            </div>
          </div>
          <div className="col-4 right-flex">
            <span className="sec-split-two">
              {/* <img src={HomeBanner} /> */}
            </span>
          </div>
        </div>
      </section>

      <section className="aboutleader">
        <header>
          <h2>Leadership</h2>
        </header>
        {/* <div className="row"> */}
        <div className="row listcontainer d-flex">
          {listData &&
            listData.map((val, index) => (
              <div className="col-l2 col-sm-6 col-md-6 col-lg-6 col-xl-4 pb-md-5 pt-md-5 pb-3 pt-3">
                <span className="imgbg">
                  <img type="img" src={val.background} alt="background" />
                  <div className="history">
                    <label>{val.name}</label>
                    <p>
                      {val.position}
                      <RenderPopup
                        lastcard={listData.length}
                        item={val}
                        index={index}
                      />
                    </p>
                  </div>
                </span>
              </div>
            ))}
        </div>
        {/* </div> */}
      </section>
    </div>
  );
}

export default aboutlist;
