import React from "react";
import "./nav.scss";
import Nav from "react-bootstrap/Nav";
import { Navbar, NavDropdown, Container } from "react-bootstrap";
import Headerlogo from "../../images/logo/Headerlogo.svg";
const Navbars = ({ history }) => {
  const pathname = window.location.pathname;
  const menuList = [
    { id: 1, name: "Home", path: "/", active: true },
    { id: 2, name: "Solution", path: "/solution", active: false },
    { id: 3, name: "Products", path: "/products", active: false },
    // { 'id': 4, 'name': 'Resources', 'path':'/resources', 'active': false },
    { id: 5, name: "About us", path: "/aboutus", active: false },
    { id: 6, name: "Contact us", path: "/contact-us", active: false },
  ];

  return (
    <div className="menubar">
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <header className="navbar-container col-12">
          <div className="col-2">
            <span className="navlogo">
              <a href="/">
                <img src={Headerlogo} alt="Headerlogo" />
              </a>
            </span>
          </div>
          <div className="col-10">
            <ul>
              <li>
                {menuList &&
                  menuList.map((item) =>
                    item.name === "Products" ? (
                      <div className="dropdown">
                        <button className="dropbtn">
                          Products
                          <i className="fa fa-caret-down"></i>
                        </button>
                        <div className="dropdown-content">
                          <ul>
                            <li>
                              <a href="/products#Cortex">Hive SaaS</a>
                            </li>
                            <li>
                              <a href="/products#Hive">Hive</a>
                            </li>
                            <li>
                              <a href="/products#Chart">HiveChart</a>
                            </li>
                            <li>
                              <a href="/products#Pod">HivePod</a>
                            </li>
                            <li>
                              <a href="/products#MyCE">HiveCE</a>
                            </li>
                            {/* <li><a href="/products/#CogniFlow">CogniFlow</a></li> */}
                            {/*<li><a href="/products/#Conferencing">Video Conferencing</a></li> */}
                            {/* <li><a href="/products/#HiveMeet">Hive Meet</a></li> */}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <a href={item.path}>
                        {item.name}
                        {pathname === item.path ? (
                          <p className="unerline"></p>
                        ) : (
                          ""
                        )}
                      </a>
                    )
                  )}
                <a
                  href="https://hive.clinic/#/login"
                  rel="noreferrer"
                  className="btn"
                  target="_blank"
                >
                  Login
                </a>
                <a
                  href="https://hive.clinic/#/signup/FMHC"
                  rel="noreferrer"
                  className="btn"
                  target="_blank"
                >
                  Sign Up
                </a>
              </li>
            </ul>
          </div>
        </header>
      </nav>
      <div className="mobile">
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <span className="navlogo">
                <img src={Headerlogo} alt="Headerlogo" />
              </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto mobile-bg">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/solution">Solution</Nav.Link>
                <NavDropdown title="Products" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/products#Cortex">
                    Hive SaaS
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/products#Hive">
                    Hive
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/products#Chart">
                    HiveChart
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/products#Pod">
                    HivePod
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/products#MyCE">
                    HiveCE
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="/flowmed/products/#CogniFlow">CogniFlow</NavDropdown.Item> */}
                  {/* <NavDropdown.Item href="/products/#Conferencing">Video Conferencing</NavDropdown.Item>
                  <NavDropdown.Item href="/products/#Holos">Holos</NavDropdown.Item> */}
                </NavDropdown>
                {/* <Nav.Link href="#link">Resources</Nav.Link> */}
                <Nav.Link href="/aboutus">About Us</Nav.Link>
                <Nav.Link href="/contact-us">Contact us</Nav.Link>
                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default Navbars;
