import React, { Component } from "react";
import "./PrivacyPolicy.scss";
// import Chat from "../Chat";

class PrivacyPolicy extends Component {
  render() {
    return (
      // <div className='Privacypolicy'>
      <section className="Privacypolicy">
        <div className="headersection">
          <header>
            <h2>Privacy & Policy</h2>
            <p>Last modified: August 01, 2023</p>
          </header>
        </div>
        <div className="a4-page">
          <div className="innercontant">
            <div className="commondiv">
              <label>Data Privacy</label>
              <p>
                FlowMed (“We” or “Us”, as the context may be) are committed to
                protecting and respecting your privacy. This policy (together
                with our terms of use and any other documents referred to on it)
                (“Privacy Policy”) sets out the basis on which any personal data
                we collect from you, or that you provide to us, will be
                processed by us. The purpose of this policy is to give you
                confidence as you visit and use the www.flowmed.ca and/or our
                app (Hive Kit, Hive SaaS, Hive Lite, Hive Chart, and to
                demonstrate our commitment to fair information practices and the
                protection of privacy. This policy is only applicable to this
                Site. Please read the following carefully to understand our
                views and practices regarding your personal data and how we will
                treat it. By using the Site you are accepting and consenting to
                the practices described in this Privacy Policy. If you do not
                understand any aspect of our Privacy Policy, please feel free to
                contact us using the information found at the end of the Privacy
                Policy. For the purpose of the Data Protection Act 1998, the
                data controller is Hive SaaS, part of FlowMed Inc. of 909-2220
                Lake Shore Blvd. West, Toronto ON M8V 0C1, Canada.
              </p>
              {/* <p>This version was last updated on 21 May 2023.</p> */}

              <div className="commondiv">
                <label>Under 18 Years of Age</label>
                <p>
                  Our Site is not eligible for use by persons under 18 years of
                  age without legal parental or guardian consent. No one under
                  age 18 may provide any personal information to or on our Sites
                  without legal parental or guardian consent. We do not
                  knowingly collect personal information from persons under 18
                  if such information is provided without legal parental or
                  guardian consent. If you are under 18, do not use or provide
                  any information about yourself to us, including your name,
                  address, telephone number, e-mail address or any screen name
                  or user name you may use without legal parental or guardian
                  consent. If we learn we have collected or received personal
                  information from a person under 18 without verification of
                  parental consent, we will delete that information. If you
                  believe we might have any information from or about a person
                  under 18 for which there is no legal parental or guardian
                  consent, please Contact Us.
                </p>
              </div>

              <div className="commondiv">
                <label>Information we collect from you</label>
                <p>We will collect and process the following data about you:</p>
                <p>
                  Information you give us. This is information about you that
                  you give us by filling in forms on our Site or by
                  corresponding with us by phone, e-mail or otherwise. It
                  includes information you provide when you register to use our
                  Site, subscribe to our Services, carry out an appointment with
                  Hive SaaS Platform and when you report a problem with our
                  Site. Identity Data includes first name, last name, address,
                  username or similar identifier, marital status, title, date of
                  birth and gender. In certain circumstances, it may also
                  include your National/Emirates ID number and/ or copy,
                  Passport number and/or copy, Insurance ID number and/or copy,
                  Nationality, place of work and work ID number. Contact Data
                  includes billing address, delivery address, location, email
                  address and/or telephone numbers.
                </p>
                <p>Photos or other data uploaded onto our Site by you;</p>
                <p>
                  Communications with us via social media platforms, email,
                  electronic messages and other electronic and non-electronic
                  communications;
                </p>
                <p>
                  Your networks and connections made available to us, depending
                  on the permissions you have granted, from your mobile and
                  desktop devices’ address book contacts, and other social media
                  such as Facebook, Instagram, and Twitter. Financial Data
                  includes bank account and payment card details, payment
                  history;
                </p>
                <p>
                  Sensitive medical data (which may include your medical
                  history, health status and testing results, and any other
                  non-public health information);
                </p>
                <p>
                  Marketing and Communications Data includes your preferences in
                  receiving marketing from us and our third parties and your
                  communication preferences;
                </p>
                <p>
                  Information we collect about you. With regard to each of your
                  visits to our Site, we may collect the following information
                  provided by you or your employer or insurer:
                </p>
                <p>Mobile number</p>
                <p>Email address (personal or corporate)</p>
                <p>Employee ID</p>
                <p>Insurance Category</p>
                <p>Insurance Coverage and other policy related details</p>
                <p>Marital Status</p>
                <p>Place and Department of Employment</p>
                <p>Employment Status</p>
                <p>Date of Birth</p>
                <p>Passport Number</p>
                <p>Emirates ID Number / National ID Number</p>
                <p>Details of family members (if included in policy)</p>
                <p>Nationality</p>
                <p>Country, City, and Area of Residence</p>
                <p>Visa status</p>
                <p>
                  Health information about you prepared by the Hive SaaS
                  Platform who provide the Services through the Site such as
                  medical records, treatment notes, and other health
                  information;
                </p>
                <p>
                  Transaction Data includes details about payments to and from
                  you and other details of products and services you have
                  purchased from us.
                </p>
                <p>
                  Health information about you prepared by the Hive SaaS
                  Platform who provide the Services through the Site such as
                  medical records, treatment notes, and other health
                  information; Transaction Data includes details about payments
                  to and from you and other details of products and services you
                  have purchased from us. Technical Data includes internet
                  protocol (IP) address, your login data, browser type and
                  version, make and model (mobile phones only), operating
                  system, hardware version, platform, device settings and other
                  technology identification on the devices used to access our
                  Sites, file and software names and types, device identifiers,
                  time zone setting and location, device locations such as
                  through GPS, Bluetooth or WiFi signals, browser plug-in types
                  and versions, operating system and platform, connection
                  information such as the name of your mobile operator or ISP,
                  browser type, language and time zone, mobile phone number and
                  IP address.
                </p>
                <p>
                  Profile Data includes your username and password, purchases or
                  orders made by you, your interests, preferences, feedback and
                  survey responses.
                </p>
                <p>
                  Usage Data includes information about how you use our Sites,
                  how you use your devices to access our Sites, including the
                  screens you visit
                </p>
                <p>
                  Information we receive from other sources. This is information
                  we receive about you if you use any of the other websites we
                  operate or from third parties (including any third query party
                  links to our Site). In this case, we will have informed you
                  when we collected that data if we intend to share those data
                  internally and combine it with data collected on our Site. We
                  will also have told you for what purpose we will share and
                  combine your data;
                </p>
                <p>
                  Information obtained from video consultation. You consent and
                  authorize us to audio record, video record, and/or still
                  photograph your consultation.
                </p>
                <p>
                  Aggregated data. We also collect, use and share aggregated
                  data such as statistical or demographic data for any purpose.
                  Aggregated Data may be derived from your personal data but is
                  not considered personal data in law as this data does not
                  directly or indirectly reveal your identity. For example, we
                  may aggregate your Usage Data to calculate the percentage of
                  users accessing a specific Site feature. However, if we
                  combine or connect aggregated data with your personal data so
                  that it can directly or indirectly identify you we treat the
                  combined data as personal data which will be used in
                  accordance with this Privacy Policy.
                </p>
              </div>

              <div className="commondiv">
                <label>How is your data collected?</label>
                <p>
                  We use the following methods to collect data from and about
                  you:
                  <p>
                    <b>HealthKit-</b>
                    <p>
                      iOS users can allow the app to get HealthKit data
                      automatically by approving the HealthKit application to
                      send data to the mobile application. The user can also
                      revoke the permission if needed.
                    </p>
                  </p>
                </p>
                <p>
                  Direct interactions. You may give us your Identity Data,
                  Contact Data, Transaction Data, Profile Data, Financial Data,
                  Health Information and Marketing and Communications Data by
                  using our Site filling in forms or by corresponding with us by
                  phone, email, in person or otherwise. This includes personal
                  data you provide when you
                </p>
                <p>purchase a Service</p>
                <p>use the Service with a Hive SaaS Platform</p>
                <p>create an account on our Site</p>
                <p>request marketing to be sent to you;</p>
                <p>enter a marketing campaign</p>
                <p>give us feedback or contact the support team</p>
                <p>
                  Automated technologies or interactions. As you interact with
                  our website, we may automatically collect Usage Data and
                  Technical Data about your equipment, browsing actions and
                  patterns. We collect this personal data by using cookies,
                  server logs and other similar technologies.
                </p>
                <p>Data provided by your Employer</p>
                <p>Data provided by your Insurer</p>
                <p>Cookies</p>
                <p>
                  Cookies are small computer files that are transferred to your
                  computer’s hard drive that contain information such as user
                  ID, user preferences, lists of pages visited and activities
                  conducted while browsing the Site. At your option, expense and
                  responsibility, you may block cookies or delete cookies from
                  your hard drive. However, by disabling cookies, you may not
                  have access to the entire set of features of this Site. Our
                  Site uses cookies to distinguish you from other users. This
                  helps us to provide you with a good experience when you browse
                  our Site and also allows us to improve our services.
                </p>
                <p></p>
              </div>

              <div className="commondiv">
                <label>Location Information</label>
                <p>
                  You can choose whether or not to allow our Sites to collect
                  and use real-time information about your device’s location
                  through the device’s privacy settings. If you block the use of
                  location information, some parts of our Sites may then be
                  inaccessible or not function properly.
                </p>
                <p>
                  If we need to use your personal data for an unrelated purpose,
                  we will notify you and we will explain the legal basis which
                  allows us to do so. Please note that we may process your
                  personal data without your knowledge or consent, in compliance
                  with the above rules, where this is required or permitted by
                  law.
                </p>
              </div>

              <div className="commondiv">
                <label>Links to Third Party Sites</label>
                <p>
                  Our site may, from time to time, contain links to and from the
                  websites of our partner networks, advertisers and affiliates.
                  If you follow a link to any of these websites, please note
                  that these websites have their own privacy policies and that
                  we do not accept any responsibility or liability for these
                  policies. Please check these policies before you submit any
                  personal data to these websites.
                </p>
              </div>

              <div className="commondiv">
                <label>How we use your personal data</label>
                <p>
                  We will only use your personal data when the law allows us to.
                  We use information held about you in the following ways:
                </p>
              </div>

              <div className="commondiv">
                <p>
                  to carry out our obligations to you and to provide you with
                  the information, and medical or other services that you
                  request from us including treatment, payment and health care
                  operations;
                </p>
                <p>
                  {" "}
                  We keep a record of the health information you provide us
                  including test results, diagnoses, medications etc. which we
                  may disclose to laboratories, doctors, pharmacies, or other
                  healthcare providers involved in your care;{" "}
                </p>
                <p>
                  {" "}
                  We keep a record of the services and supplies you receive when
                  we are providing care to you so that this information can be
                  used for billing purposes. This information may be provided to
                  your insurance company if the service is being reimbursed by
                  them;
                </p>
                <p>
                  We may use your health information to review our services and
                  to evaluate the performance of the Hive SaaS Platform carrying
                  out the Services;
                </p>
                <p>
                  We may be required to provide your personal data to regulators
                  as necessary;
                </p>
                <p>
                  We will pass on your personal data to a court of law when a
                  court order has been issued;
                </p>
                <p>
                  to provide you with information about other services we offer
                  that are similar to those that you have already purchased or
                  enquired about;
                </p>
                <p>
                  to provide you, or permit selected third parties to provide
                  you, with information about goods or services we feel may
                  interest you. If you are an existing customer, we will only
                  contact you by electronic means (e-mail, SMS or push message)
                  with information about goods and services similar to those
                  which were the subject of a previous sale or negotiations of a
                  sale to you. If you are a new user, and where we permit
                  selected third parties to use your data, we (or they) will
                  contact you by electronic means only if you have consented to
                  this;
                </p>
                <p>to notify you about changes to our Service;</p>
                <p>
                  to ensure that content from our Site is presented in the most
                  effective manner for you and for your computer;
                </p>
                <p>
                  to administer our Site for internal operations, including
                  troubleshooting, data analysis, testing, research, statistical
                  and survey purposes;
                </p>
                <p>
                  to improve our Site to ensure that content is presented in the
                  most effective manner for you and for your computer;
                </p>
                <p>
                  to allow you to participate in interactive features of our
                  Service, when you choose to do so;
                </p>
                <p>as part of our efforts to keep our Site safe and secure;</p>
                <p>
                  to measure or understand the effectiveness of advertising we
                  serve to you and others, and to deliver relevant advertising
                  to you;
                </p>
                <p>
                  to make suggestions and recommendations to you and other users
                  of our Site about goods or services that may interest you or
                  them;
                </p>
                <p>to comply with a legal or regulatory obligation;</p>
                <p>Marketing;</p>
                <p>
                  We will always give you the option to choose not to receive
                  marketing communications from us;
                </p>
                <p>
                  If you do not wish to have your personal data used by Us to
                  promote our own or third parties’ products or services, you
                  can opt-out by contacting us. If we have sent you a
                  promotional e-mail, you may send us a return e-mail asking to
                  be omitted from future e-mail distributions. This opt out does
                  not apply to information provided to Us as a result of a
                  product or service purchase, product service experience or
                  other transactions.
                </p>
              </div>

              <div className="commondiv">
                <label>Targeted Advertising </label>
                <p>
                  If you do not want us to use information that we collect or
                  that you provide to us to deliver advertisements according to
                  our advertisers’ target-audience preferences, you can opt-out
                  by contacting us.
                </p>
              </div>

              <div className="commondiv">
                <label>Promotions</label>
                <p>
                  We may use your Identity, Contact, Technical, Usage and
                  Profile Data and Special Categories of Personal Data to form a
                  view on what we think you may want or need, or what may be of
                  interest to you. This is how we decide which products,
                  services and offers may be relevant for you.
                </p>
                <p>
                  You will receive marketing communications from us if you have
                  requested information from us or created an account or
                  purchased services from us or if you provided us with your
                  details when you entered a competition or registered for a
                  promotion and, in each case, you have not opted out of
                  receiving that marketing.
                </p>
              </div>

              <div className="commondiv">
                <label>Third-party Marketing</label>
                <p>
                  We will get your express opt-in consent before we share your
                  personal data with any third-party company for marketing
                  purposes. We do not control third parties’ collection or use
                  of your information to serve interest-based advertising.
                  However, these third parties may provide you with ways to
                  choose not to have your information collected or used in this
                  way.
                </p>
              </div>

              <div className="commondiv">
                <label>Marketing Opt Out</label>
                <p>
                  You can ask us or third parties to stop sending you marketing
                  messages at any time by following the opt-out links on any
                  marketing message sent to you or by contacting us at any time.
                </p>
                <p>
                  Where you opt out of receiving these marketing messages, this
                  will not apply to personal data provided to us as a result of
                  a product/service purchase, warranty registration,
                  product/service experience or other transactions.
                </p>
              </div>

              <div className="commondiv">
                <label>Disclosure of Your information</label>
                <p>
                  You agree that we have the right to share your personal
                  information, with the exception of your personal health
                  records, with any member of our group, which means our
                  subsidiaries, our ultimate holding company and its
                  subsidiaries.
                </p>
              </div>

              <div className="commondiv">
                <label>We may disclose your personal information to:</label>
              </div>

              <div className="commondiv">
                <p>
                  contractors, health care professionals, which may include
                  nurses and physicians, service providers, business associates,
                  our staff and any third parties we use to support our business
                  and to provide health care services;
                </p>
                <p>
                  advertisers that require the data to select and serve relevant
                  adverts to you and others;
                </p>
                <p>
                  analytics and search engine providers that assist us in the
                  improvement and optimization of our Site;
                </p>
                <p>
                  other Service providers, health plans, or their related
                  entities for their treatment or payment activities, or health
                  care activities;
                </p>
                <p>
                  third parties in the event that we sell or buy any business or
                  assets, in which case we may disclose your data to the
                  prospective seller or buyer of such business or assets;
                </p>
                <p>
                  third parties if Health at Hand or substantially all of its
                  assets are acquired by a third party, in which case personal
                  data held by it about its customers will be one of the
                  transferred assets;
                </p>
                <p>
                  third parties if we are under a duty to disclose or share your
                  personal data in order to comply with any legal obligation,
                  regulation, subpoena, court order, legal process or government
                  request or in order to enforce or apply our terms of use and
                  other agreements; or to protect the rights, property, or
                  safety of Health at Hand, our customers, or others;
                </p>
                <p>
                  third parties when we believe in good faith that disclosure is
                  necessary to protect your safety or the safety of others, to
                  protect our rights, to investigate fraud, or to respond to a
                  government request;
                </p>
                <p>
                  third parties to notify or assist in notifying a family
                  member, personal representative or another person responsible
                  for your care of your location and general condition;
                </p>
                <p>
                  You further agree that we may make your patient notes (whether
                  provided by you or created by a doctor during a Health at Hand
                  consultation) available to any and all other doctors and
                  clinics on the Health at Hand platform;
                </p>
              </div>

              <div className="commondiv">
                <label>International Transfers</label>
                <p>
                  We may share your personal data within the Hive SaaS Platform.
                  This may involve transferring your data outside of your
                  country of residence.
                </p>
                <p>
                  We ensure your personal data is protected by requiring all our
                  group companies to follow the same rules when processing your
                  personal data.
                </p>
                <p>
                  External third parties may be based outside your country of
                  residence so their processing of your personal data will
                  involve a transfer of data outside your country of residence.
                  Whenever we transfer your personal data out of your country of
                  residence, we ensure a similar degree of protection is
                  afforded to it by ensuring at least one of the following
                  safeguards is implemented.
                </p>
                <p>
                  Where we use certain service providers, we may use specific
                  contracts which give personal data the same protection it has
                  in Europe or other relevant jurisdiction. Where we use
                  providers based in the US, we may transfer data to them if
                  they are part of the Privacy shield which requires them to
                  provide similar protection to personal data shared between
                  Europe and the US.
                </p>
                <p>
                  In the event of a conflict between laws or regulations set
                  forth by the relevant authorities in our country of operation
                  (“Local Regulations”) and any other laws or regulations, the
                  Local Regulations will prevail.
                </p>
              </div>

              <div className="commondiv">
                <label>Where we store your personal data</label>
                <p>
                  We will at all times comply with the Local Regulations in our
                  country of operations and will take all steps reasonably
                  necessary to ensure that your data is treated securely and in
                  accordance with this Privacy Policy.
                </p>
                <p>
                  We store all your personal health data – including your
                  primary care information, secondary care information,
                  medication information and diagnostic information – on secure
                  servers. Any payment transactions will be encrypted. Where we
                  have given you (or where you have chosen) a password which
                  enables you to access certain parts of our Site, you are
                  responsible for keeping this password confidential. We ask you
                  not to share a password with anyone.
                </p>
                <p>
                  We do not store any credit or debit card information. Payments
                  are processed via a third party payment provider that is fully
                  compliant with Level 1 Payment Card Industry (PCI) data
                  security standards. Any payment transactions are encrypted
                  using SSL technology.
                </p>
                <p>
                  Once we have received your information, we will use strict
                  procedures, industry standard physical, technical and
                  administrative safeguards and security features to try to
                  prevent unauthorized access.
                </p>
              </div>

              <div className="commondiv">
                <label>Protection of Passwords</label>
                <p>
                  Your account is protected by your account password, and we
                  urge you to take steps to keep your personal information safe
                  by not disclosing your password and by logging out of your
                  account after each use. It is your responsibility to control
                  the dissemination and use of your password, and to control
                  access to and use of your user ID and password. If you need to
                  deactivate your account, promptly inform us. You agree to
                  promptly notify us of any unauthorized use of your username,
                  password, or any other breach of security that you become
                  aware of.
                </p>
              </div>

              <div className="commondiv">
                <label>Data Retention</label>
                <p>
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal,
                  accounting, or reporting requirements. This includes the legal
                  directives specified by the Dubai Health Authority as the
                  medical regulator.
                </p>
                <p>
                  To determine the appropriate retention period for personal
                  data, we consider the amount, nature, and sensitivity of the
                  personal data, the potential risk of harm from unauthorized
                  use or disclosure of your personal data, the purposes for
                  which we process your personal data and whether we can achieve
                  those purposes through other means, and the applicable legal
                  requirements. In some circumstances, we may anonymize your
                  personal data (so that it can no longer be associated with
                  you) for research or statistical purposes in which case we may
                  use this information indefinitely without further notice to
                  you.
                </p>
              </div>

              <div className="commondiv">
                <label>Your Rights</label>
                <p>
                  You have the right to make the following requests at any time:
                </p>
              </div>

              <div className="commondiv">
                <p>
                  Request access to your personal data (commonly known as a
                  “data subject access request”). This enables you to receive a
                  copy of the personal data we hold about you and to check that
                  we are lawfully processing it.
                </p>
                <p>
                  Request correction of the personal data that we hold about
                  you. This enables you to have any incomplete or inaccurate
                  data we hold about you corrected, though we may need to verify
                  the accuracy of the new data you provide to us. The correction
                  of medical records will only be done as a timestamped
                  addendum. Previous notes written by our doctors will never be
                  changed or erased.
                </p>
                <p>
                  Request erasure of your personal data. This enables you to ask
                  us to delete or remove personal data – with the exception of
                  medical records – where there is no good reason for us
                  continuing to process it. You also have the right to ask us to
                  delete or remove your personal data where you have
                  successfully exercised your right to object to processing (see
                  below), where we may have processed your information
                  unlawfully or where we are required to erase your personal
                  data to comply with local law. Note, however, that we may not
                  always be able to comply with your request of erasure for
                  specific legal reasons which will be notified to you, if
                  applicable, at the time of your request. Dubai Health
                  Authority regulations will always be followed in this
                  instance.
                </p>
                <p>
                  Object to processing of your personal data where we are
                  relying on a legitimate interest (or those of a third party)
                  and there is something about your particular situation which
                  makes you want to object to processing on this ground as you
                  feel it impacts on your fundamental rights and freedoms. You
                  also have the right to object where we are processing your
                  personal data for direct marketing purposes. In some cases, we
                  may demonstrate that we have compelling legitimate grounds to
                  process your information which override your rights and
                  freedoms.
                </p>
                <p>
                  Request restriction of processing of your personal data. This
                  enables you to ask us to suspend the processing of your
                  personal data in the following scenarios: (a) if you want us
                  to establish the data’s accuracy; (b) where our use of the
                  data is unlawful but you do not want us to erase it; (c) where
                  you need us to hold the data even if we no longer require it
                  as you need it to establish, exercise or defend legal claims;
                  or (d) you have objected to our use of your data but we need
                  to verify whether we have overriding legitimate grounds to use
                  it.
                </p>
                <p>
                  Request the transfer of your personal data to you or to a
                  third party. We will provide to you, or a third party you have
                  chosen, your personal data in a structured, commonly used,
                  machine-readable format. Note that this right only applies to
                  automated information which you initially provided consent for
                  us to use or where we used the information to perform a
                  contract with you.
                </p>
                <p>
                  Withdraw consent at any time where we are relying on consent
                  to process your personal data. However, this will not affect
                  the lawfulness of any processing carried out before you
                  withdraw your consent. If you withdraw your consent, we may
                  not be able to provide certain products or services to you. We
                  will advise you if this is the case at the time you withdraw
                  your consent.
                </p>
                <p>
                  You can exercise the right at any time by contacting us at{" "}
                  <em className="supportclass">
                    <a href="mailto:support@flowmed.ca">support@flowmed.ca</a>
                  </em>{" "}
                  You will not have to pay a fee to access your personal data
                  (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we may refuse to
                  comply with your request in these circumstances.
                </p>
                <p>
                  You may also request that a copy of your information be
                  provided to another person. We try to respond to all
                  legitimate requests within one month. Occasionally it may take
                  us longer than a month if your request is particularly complex
                  or you have made a number of requests. In this case, we will
                  notify you and keep you updated.
                </p>
              </div>

              <div className="commondiv">
                <label>Changes to our Privacy Policy</label>
                <p>
                  Any changes we make to our Privacy Policy in the future will
                  be posted on this page and, where appropriate, notified to you
                  by e-mail. Continued use of our Site following notice of such
                  changes will indicate your acknowledgement of such changes and
                  agreement to be bound by the terms and conditions of such
                  changes. By using the Site, you are agreeing to our
                  collection, use and disposal of you personal information and
                  other data as described in this policy, both as it exists now
                  and as it is changed from time to time. Please check back
                  frequently to see any updates or changes to our Privacy
                  Policy.
                </p>
              </div>
              <div className="commondiv">
                <label>Contact</label>
                <p>
                  Questions, comments and requests regarding this Privacy Policy
                  are welcomed and should be addressed to{" "}
                  <em className="supportclass">
                    <a href="mailto:support@flowmed.ca">support@flowmed.ca</a>
                  </em>
                </p>
              </div>

              {/* <div className='commondiv'>
                <p>Hive SaaS, August 2020</p>
                <p>Stay Informed</p>
                <p>Login</p>
                <p>Quick Links</p>
                <p>Privacy Policy</p>
                <p>About Us</p>
                <p>Product and Services</p>
                <p>Contact Us</p>
              </div> */}
            </div>
          </div>
        </div>

        {/* <Chat/> */}
      </section>

      // </div>
    );
  }
}

export default PrivacyPolicy;
