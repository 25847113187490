import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./components/FontawesomeIcons";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import './globalStyle.scss';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import Contactus from "./pages/Contactus";
import Products from "./pages/Products";
import About from "./pages/About";
import Solution from "./pages/Solution";


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="main">
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route exact path="/solution/"><Solution/></Route>
          <Route path="/products/"><Products/></Route>
          <Route exact path="/aboutus/"><About/></Route>
          <Route path={"/contact-us/"}><Contactus/></Route>
          <Route path="/privacy-policy/"><PrivacyPolicy /></Route>
        </Switch>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
