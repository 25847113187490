import React from 'react';
import './Products.scss';
import homebanner1 from '../../images/banner/product_img.png';
import MyCortexEco from '../../images/banner/MyCortexEco.png';
// import HiveLogo from '../../images/logo/hivelogo.svg';
import HiveChart from '../../images/banner/HiveChart.png';
// import HiveChart from '../../images/banner/HiveChart1.png';


function Product() {
  return (
    <div className="producthead">
      <header>
        <h2>Products By FlowMed</h2>
      </header>

      <section className='productlist' id='Cortex'>
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>Hive SaaS </h3>
              <p>
                {/* <img src={HiveLogo} alt="hivelogo"/>  */}
              Hive SaaS is the central health information repository hosting patients’ records.  It forms the virtual encounter point of health providers and patients.  The platform is built on world class technology that addresses scalability, resiliency, and cyber-security.
              {/* <img src={HiveLogo} alt="hivelogo"/>  */}
              Hive SaaS hosts a multitude of modules including lifestyle, medical protocols, payment/insurance gateway, virtual consultation, and a full appointment booking module.  In addition, 
              {/* <img src={HiveLogo} alt="hivelogo"/>  */}
              Hive SaaS includes an HL7 engine that allows integration to the providers’ EMR/EHR system.
                Smart devices connect to Hive SaaS securely through the various carrier or wifi means. </p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={MyCortexEco} alt='background' />
            </span>
          </div>
        </div>
      </section>

      <section className='productlist' id="Hive">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>Hive</h3>
              <p>Hive is a multi-user point of care medical engagement tablet.  It enables individuals in the same household to engage their healthcare provider through an innovative workflow that mimics the experience of primary care and disease management in the Virtual space.  Hive enables the user to choose a doctor, book an appointment and transact the payment.  Using Hive, physicians video conference with their patients while simultaneously viewing real time comprehensive monitoring data for further assessment and medical decisions </p>
              <p>Hive easy to use touch screen purpose packaged medical tablet engages patients to securely share lifestyle and personal medical data with the care team.  Utilising branded medical accessories pre-configured to the conditions being monitored, no at-home setup or configuration will be required and out of box immediate operation is achieved.</p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={homebanner1} alt='background' />
            </span>
          </div>
        </div>
      </section>

      <section className='productlist' id="Chart">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>HiveChart</h3>
              <p>HiveChart is a multi-user clinical tablet for healthcare professionals (HCPs).  It empowers travelling HCPs to manage patients effectively and conduct more comprehensive diagnostics at the point of care.  The HCPs collect medical data, complete documentation and schedule appointments with clinicians.  HCPs connect with other HCPs via video conference with their patients and simultaneously view real time monitoring data for further assessment and medical decisions.</p>
              <p>HiveChart is integrated with best of breed CE and FDA approved medical accessories allowing HCPs to seamlessly save the medical parameters to the patient record and share it with their team.  HiveChart adheres to best practices of disinfection according to the organisation's protocol to enhance contamination prevention.</p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={HiveChart} alt='background' />
            </span>
          </div>
        </div>
      </section>

      <section className='productlist' id="Pod">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>HivePod</h3>
              <p>HivePod is a next-gen telehealth tool with advanced capabilities.  It includes a PTZ camera and a controller which enables a seamless setup of remote clinics.</p>
              <p>HivePod connects to the Hive SaaS ecosystem for a holistic remote healthcare solution.  Doctors will realise value utilising the HivePod in attending cases of patients with mobility challenges. </p>
              <p>The integration of remote patient monitoring with other advanced digital therapeutic solutions in the Hive network is a win-win situation for both doctors and patients.  Doctors can experience improved productivity and generate better outcomes for their patients.  Advanced data and analytics also help them understand the health of their patients much better.</p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={homebanner1} alt='background' />
            </span>
          </div>
        </div>
      </section>

      <section className='productlist' id="MyCE">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>HiveCE</h3>
              <p>HiveCE is an in-house coordination engine designed to manage any type of interoperability and provide seamless bidirectional data flow between various health systems. Integrate with EMR, National Health exchange, appointment modules , hospital HR system, single sign on and many.  </p>
              <p>The bidirectional communication of medical and wellness data elevates the value doctors can achieve in their aim to offer the best possible care for better patient outcomes.</p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={homebanner1} alt='background' />
            </span>
          </div>
        </div>
      </section>

      {/* <section className='productlist' id="HiveMeet">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>Hive Meet</h3>
              <p>HiveMeet is an embedded homegrown video conferencing platform that allows doctors and patients to have a virtual encounter.  It uses secure technologies to ensure the privacy of communications.  Patients can connect with their doctors on this platform through the virtual clinical workflow and get medical consultation.</p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={homebanner1} alt='background' />
            </span>
          </div>
        </div>
      </section> */}

      {/* <section className='productlist' id="CogniFlow">
        <div className='row listpadding'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
            <div className='flexdirection'>
              <h3>CogniFlow</h3>
              <p>CogniFlow is an AI tool designed to categorise the patient population and enable better decision support for doctors.  The system will look at patients' historical medical data and assign that patient to their risk categories.  This information is essential for doctors to make better decisions for their patients' treatments. </p>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
            <span>
              <img type="img" src={homebanner1} alt='background' />
            </span>
          </div>
        </div>
      </section> */}

      {/* <section className='productlist' id="Conferencing">
          <div className='row listpadding'>
            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
              <div className='flexdirection'>
                <h3>Video Conferencing</h3>
                <p>XYZ is a video conferencing platform that allows doctors and patients to connect online. It uses secure and compliant technologies to ensure the privacy of communications. Patients can connect with their doctors on this platform to discuss a wide range of issues related to their health, such as chronic pain, mental health concerns, and more. </p>
                <p>The easy-to-use, cost-effective solution allows health professionals to connect with patients from anywhere. This allows them to provide care without having to be in the same room as their patients, which can be especially helpful when there are long distances between them or when they need to be able to see the entire body of their patient to provide proper care.</p>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
              <span>
                <img type="img" src={homebanner1} alt='background' />
              </span>
            </div>
          </div>
        </section>

        <section className='productlist' id="Holos">
          <div className='row listpadding'>
            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
              <div className='flexdirection'>
                <h3>Holos</h3>
                <p>Holos is a B2C platform designed for everyone, from patients to doctors. It has a pool of pre-vetted and certified doctors offering their services for a range of ailments. Patients can register themselves on the platform to access the advanced features and look for the best remedy for their various ailments. </p>
                <p>Doctors, too, can register themselves to offer their services. The seamless database of doctors at their fingertips helps patients to get instant treatment. Gone are the days of the past when location barriers meant patients cannot get treatment in time. Holos is here to simplify the lives of everyone by enabling advanced patient care solutions.</p>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 right_img'>
              <span>
                <img type="img" src={homebanner1} alt='background' />
              </span>
            </div>
          </div>
        </section> */}
    </div>
  );
}


export default Product;